<template>
  <div id="user-container" v-if="accessibleRoutes.user">
    <div id="user-btn-group" no-gutters>
      <div class="col-12 p-responsive">
        <div class="col-12">
          <v-btn-toggle v-model="toggle">
            <router-link to="/users/groups">
              <v-btn :ripple="false" :disabled="toggle == 0"> User Group Management </v-btn>
            </router-link>
            <router-link to="/users/list" :class="accessibleRoutes.setting || accessibleRoutes.log ? 'tab-3-menu' : ''">
              <v-btn :ripple="false" :disabled="toggle == 1">
                User Management</v-btn
              ></router-link
            >
          </v-btn-toggle>
        </div>
      </div>
    </div>
    <div class="col-12 pt-0 pb-0">
      <router-view></router-view>
    </div>
  </div>
</template>

<style lang="scss" src="../../assets/css/users.scss"></style>

<script>
export default {
  data() {
    return {
      toggle: 0,
    };
  },
  created() {
    this.toggle = 0;
    if (this.$router.currentRoute.name == "User Management") {
      this.toggle = 1;
    } else if (this.$router.currentRoute.name == "Settings") {
      this.toggle = 2;
    } else if (this.$router.currentRoute.name == "Logs") {
      this.toggle = this.accessibleRoutes.setting ? 3 : 2;
    }
  },
  computed: {
    accessibleRoutes: function () {
      return this.$store.getters.accessibleRoutes;
    }
  },
};
</script>
